<template>
  <swiper ref="images-preview-swiper" :options="swiperOptions">
    <swiper-slide v-for="(data, index) in listData" :key="index" class="d-flex justify-content-center">
      <b-img :src="data.image_preview" height="500" />
    </swiper-slide>
    <div slot="button-next" class="swiper-button-next" />
    <div slot="button-prev" class="swiper-button-prev" />
    <div slot="pagination" class="swiper-pagination" />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: { Swiper, SwiperSlide },
  props: {
    listData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  background-color: #47526f;
}
.swiper-pagination-bullet {
  background-color: red;
}
.swiper-button-next {
  span {
    background-color: red;
  }
}
</style>
